import { useRef } from "react";
import { useInView } from "framer-motion";
import "animate.css";

import InsightCard from "components/InsightCard/index";
import readnow from "assets/icons/readnow.svg";
import "./insightsArticles.scss";
import { useNavigate } from "react-router-dom";

import image1 from "assets/images/slider-1.png";
import image2 from "assets/images/Container.png";
import image3 from "assets/images/Container-1.png";
import image4 from "assets/images/Container-2.png";
import image5 from "assets/images/Container-3.png";
import image6 from "assets/images/Container-4.png";

const InsightsArticles = () => {
  const navigate = useNavigate();
  const articles = [
    {
      id: 1,
      title: "",
      subtitle: "",
      description:
        "Fostering voluntary, nature-based GHG offsets through cost-efficient MRV tools | Part 1",
      image: image1,
      buttonText: "Read Now",
      buttonIcon: readnow
    },
    {
      id: 2,
      title: "",
      subtitle: "",
      description:
        "Fostering voluntary, nature-based GHG offsets through cost-efficient MRV tools | Part 2",
      image: image2,
      buttonText: "Read Now",
      buttonIcon: readnow,
      idName: "fostering"
    },
    {
      id: 3,
      title: "",
      subtitle: "",
      description: "Landscape Carbon Modeling",
      image: image3,
      buttonText: "Read Now",
      buttonIcon: readnow
    },
    {
      id: 4,
      title: "",
      subtitle: "",
      image: image4,
      description: "Rice Methane Emission",
      buttonText: "Read Now",
      buttonIcon: readnow
    },
    {
      id: 5,
      title: "",
      subtitle: "",
      image: image5,
      description: "Remote Sensing and Soil Organic Carbon",
      buttonText: "Read Now",
      buttonIcon: readnow,
      idName: "remote"
    },
    {
      id: 6,
      title: "",
      subtitle: "",
      image: image6,
      description:
        "The Role of Nature-based Solution in Fighting Climate Change",
      buttonText: "Read Now",
      buttonIcon: readnow,
      idName: "nature"
    }
  ];

  const insightsArticles = useRef();
  const insightsArticlesInView = useInView(insightsArticles, {
    amount: 0.1,
    once: true
  });
  const insightsArticlesCardInView = useInView(insightsArticles, {
    amount: 0.2,
    once: true
  });

  const handleNavigation = (id) => {
    navigate(`/insights/${id}`);
  };
  return (
    <div className="insightsArticles" ref={insightsArticles}>
      <h4
        className={
          insightsArticlesInView
            ? "animate__animated animate__fadeInUp"
            : "animate__animated animate__fadeOutUp"
        }
      >
        ALL ARTICLES
      </h4>
      <h3
        className={
          insightsArticlesInView
            ? "animate__animated animate__fadeInUp"
            : "animate__animated animate__fadeOutUp"
        }
      >
        Unveiling the Unseen: A collection of In-Depth articles{" "}
      </h3>
      <div
        className={`insightsCards ${
          insightsArticlesCardInView
            ? "animate__animated animate__fadeInUp"
            : "animate__animated animate__fadeOutDown"
        }`}
      >
        {articles.map((article, index) => (
          <InsightCard key={index} data={article} action={handleNavigation} />
        ))}
      </div>
    </div>
  );
};

export default InsightsArticles;
