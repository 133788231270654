import image1 from "assets/images/Container-video1.png";
import image2 from "assets/images/Container.png";
import image3 from "assets/images/Container-1.png";
import image4 from "assets/images/Container-2.png";
import image5 from "assets/images/Container-3.png";
import image6 from "assets/images/Container-4.png";

export const insightsData = [
  {
    id: 1,
    title:
      "Fostering voluntary, nature-based GHG offsets through cost-efficient MRV tools | Part 1",
    subtitle: "The path to climate neutrality",
    description: [
      "The path to climate neutrality is difficult and requires the combined efforts of actors across industries and societies. Market-based solutions are efficient to allocate capital where impact is highest. Voluntary GHG markets can potentially play a large complementary role to mandatory compliance markets as they offer the possibility to engage a wide range of actors – and this far beyond their legal requirements.",
      "Besides technical solutions, nature-based solutions are widely seen as having huge potential as capital requirements are often relatively low. If designed correctly, nature-based solutions for GHG offsets usually also have large positive side effects on biodiversity, soil health, food security, recreational value and other ecosystem services. Several international bodies and trustworthy individuals, NGOs etc. therefore promote the development of nature-based solutions.",
      "One difficulty with voluntary markets and nature-based solutions arises from the fact that actors are largely disconnected from directly visible effects of their actions. On one hand, the positive effects on the climate are not seen immediately, and will moreover only manifest if the other actors behave in a similar manner. More importantly, nature-based solutions will often be implemented in regions with limited access and reduced possibility for personal verification.",
      "Clearly, voluntary markets would greatly benefit if one could be sure that one own’s actions are matched by others in a fair and reciprocal way, and that any given financial contribution reaches the expected beneficiary. This even applies to the most climate-conscious actors – both institutional and private – who need to trust the offered solution to fully engage.",
      "To create trust and to sustain nature-based solutions, reliable monitoring, reporting and verification (MRV) tools are needed. Today, reliable MRV tools are in their infancy and often too expensive. Indeed, it was demonstrated that the voluntary GHG market could potentially grow over several orders of magnitude compared to today’s trading volume, if MRV tools could be made more cost-efficient. High costs for MRV inherently diminish the – mostly small – financial incentives stimulating credit sellers to implement the required land management changes. These counterproductive effects of high MRV costs are particularly strong when GHG prices are low.",
      "Cost-efficient and reliable MRV tools would not only increase financial incentives for market participants to move towards climate-neutral paths but also accelerate the growth of these markets. The Taskforce on Scaling Voluntary Carbon Markets (TSVCM), the Institute of International Finance and McKinsey estimates the market for carbon credits could be worth more than $50 billion by 2030. At the same time, such tools would also sustain compliance markets, increase awareness and social coherence, and increase trust of society in stakeholders and politics."
    ],
    image: image2
  },
  {
    id: 2,
    title:
      "Fostering voluntary, nature-based GHG offsets through cost-efficient MRV tools | Part 2",
    subtitle:
      "To fully unlock the potential benefits of voluntary GHG markets, suitable nature-based solutions have to be identified",
    description: [
      "To fully unlock the potential benefits of voluntary GHG markets, suitable nature-based solutions have to be identified, matched to local conditions, and implemented in suitable places. In such a scenario, satellite-based Earth Observation (EO) offers the necessary fine-granularity, constant and cost-efficient monitoring capacity to monitor and verify the correct implementation of nature-based solutions and to quantify the carbon stocks.",

      "For example, EO permits to detect in almost real-time deforestation within the tropical forests but also removal and conversion of mangrove forests. Such drastic changes in land cover generally cause huge releases of GHG into the atmosphere while negatively impacting biodiversity and other ecosystem services. Similarly, satellites permit to monitor the correct implementation of projects aiming to convert (degenerated) pastures in carbon absorbing agro-forestry systems. Using satellites, it is possible to quantify methane emission from rice cultivation by tracking the growth cycle and in particular the time span and duration the paddies are watered. Methane is an important GHG which needs to be addressed alongside carbon dioxide to reach net zero and climate neutrality. Using their advanced sensing technologies, remote sensing satellites moreover are able to quantify the biomass of pastures and thereby optimizing the grazing regime for maximum carbon uptake while limiting the land needed for pastures. Last but not least, using satellites it is possible not only to directly quantify the amount of carbon absorbed by agricultural soils (e.g. the humus content) but also to verify good agricultural (regenerative) practices such as no-till, cover crops and crop rotations.",

      "EO also offers the possibility to identify suitable areas for nature-based project implementation and permits the establishment of baseline scenarios. EO data is also suitable to demonstrate the necessary additionality of the implementation. Clearly, the steadily increasing data availability further sustains the huge potential for cost-efficient EO-based MRV solutions across the globe.",

      "The huge potential of satellite-based EO system is the result of several unique \n\n- observationCapabilities",

      [
        "Global and constant coverage of the entire Earth at very high revisit frequencies to detect changes in the land surface in almost real-time",
        "Generation of images at high spatial resolution to detect even small elements on the Earth surface",
        "Advanced cameras and sensors that measure the reflected/emitted radiation in many information-rich wavelengths not even visible to the human eye",
        "Close link between the satellite-measured spectral signature and the properties, characteristics and (bio)chemical composition of the observed soils, forests and agricultural systems",
        "Extremely low costs per square kilometre area",
        "Freely available data archive going back to the 1970s"
      ],

      "Together with the general advancement of the science of remote sensing as well as machine learning/AI, and the and the availability of IT solutions for processing the big data, satellite-based EO provides a unique and unmatched capability to implement cost-efficient and reliable MRV solutions.",

      "But ideally, such a system should not only be reliable and cost-efficient, but also allow participation of interested individuals. Engagement not only increases trust in the voluntary markets but potentially also enables improved monitoring and verification results. Individuals could for example participate through crowd-sourcing and by providing valuable local information for fine-tuning and calibration of the machine-learning/AI tools. Probably, it is also motivating for credit buyers to see – from space – their projects developing over time. Credit sellers, on the other hand, have needs with respect to privacy while welcoming any information supporting, facilitating and de-risking the tedious land management. EO, together with crowd sourcing, agronomy and forestry, can provide such feedback to the land owners for improving their land management, while informing all stakeholders in the voluntary markets about project status and compliance."
    ],
    image: image1
  },
  {
    id: 3,
    title: "Landscape Carbon Modelling",
    subtitle: "",
    description: [
      "Landscape-scale carbon modelling techniques assess the C exchange between atmospheric and terrestrial pools (vegetation and soil), taking into account human activities, natural disturbances as well as climate change effects. Landscape modelling integrates spatial variability of land cover (e.g. agriculture, grassland, and forestry) and land use (e.g. fertilisation, irrigation, etc.), with the inherent spatial features and heterogeneity of land surfaces (e.g. topography), as well as key environmental controls (e.g. rainfall, temperature, solar radiation, and soil moisture).",

      "Mitigation of climate change promotes C storage and sequestration in the terrestrial pool. While the term “storage” does not necessarily imply a net removal of CO2 from the atmosphere, “sequestration” does. Sequestration of C, especially in soils, may feature residence times ranging from years to millennia while enhancing at the same time soil quality and ecosystem functioning, resulting in tangible co-benefits (e.g. reduced erosion and degradation), or enhanced/stabilised ecosystem services (e.g. nutrient cycling, water retention).",

      "Landscape C modelling is a relatively complex endeavour, as both organic C sequestration in the living biomass (above- and belowground) and soils should be considered, for completeness. Different approaches are available for modelling these elements, which are constantly tested and improved by researchers and practitioners.",

      "C sequestration in biomass is closely linked to plant growth and the corresponding growth models are well known (e.g. sigmoid curves for annual crops, bi-linear curves for grasslands, and Weibull or Chapman-Richards-style asymptotic exponential curves for trees). With increasing computational power, non-linear asymptotic models have become a preferred option. For modelling aboveground C, allometric equations, harvest indices, and C partitioning among plant organs are commonly used to estimate the amount of biomass per individual plant and plant compartment (e.g. leaf, stem, root). It permits extrapolation at the desired geographic scale through assumptions on species diversity, density and age. At the landscape level, often even-aged stands are modelled, at least for forestry applications. Based on aboveground C estimations, it is relatively straightforward to derive below-ground C based for instance on allometric equations. The evolution of below-ground C over time can also be modelled using root growth models.",

      "A plethora of soil models, from the simplest soil organic matter (SOM) models (e.g. Hénin-Dupuis, AMG, RothC) to the most complex agro-ecosystem or soil-plant models (e.g. CENTURY, DAISY, STICS), offer the possibility of predicting SOM turnover based on organic C inputs to soil (i.e. organic fertilisers, plant residues, other biomass) and different pedoclimatic parameters that typically include climate, moisture, and other soil properties. Modelling SOM turnover is more complex because not all relevant mechanisms are fully understood and/or suitable operational models to represent them are scarce. The influence and effect of soil biota activity, for instance, is an active research field. Research on the determination of a potential C saturation threshold in soils is another field under continuous development.",

      "To model the evolution of soil carbon stocks, at any scale, an initial estimation is necessary. Data are obtained via field measurements (sampling) or via time- and cost-efficient techniques such as remote sensing, aimed at limiting physical sampling. Remote sensing is widely used to estimate, with great accuracy, the amount of aboveground biomass in a given area, as well as certain characteristics of (bare) soils, including organic carbon in the first centimetres of soil. Finally, assimilation of earth observational data into appropriate process models permits not only spatialisation of model outcomes, but also location-dependent parameterisation of dynamic models."
    ],
    image: image3
  },
  {
    id: 4,
    title: "Rice Methane Emissions",
    subtitle: "",
    description: [
      "Methane (CH4) is a potent greenhouse gas (GHG) which global warming potential is 28-34 times higher than that of carbon dioxide (CO2) over a 100-year time period. Viewed within a 20-year period, its power becomes 84 times higher at a par unit mass of CO2. Therefore, the spotlight is on reducing CH4 emissions to mitigate climate change. Given that Paris agreement on limiting the global temperature increase to 1.5 0C, it was pledged by the global partnership in COP-26 to cut CH4 emissions by 30% by 2030 compared to 2020. One of the major anthropogenic sources of CH4 emission is rice (oryza sativa L.) cultivation which contributes nearly 10% of global CH4 emissions. Rice CH4 emissions in 2010 was 1,120 Mt CO2-eqivalent /yr and projected to increase 1,696 Mt/year by 2050.",

      "As one of the staple foods, rice feeds half of the global population every day. To meet the food demand of a growing population, the harvested area has now increased to 162 Mha, occupying 12% of world’s arable land, mainly in Asia. China, India, Indonesia, Bangladesh, Vietnam, Myanmar, and Thailand are the major dominant rice-producing countries. Therefore, challenges remain in addressing food security using limited natural resources whilst improving soil health and mitigating the climate change impact.",

      "Conventional continuous flooding (CF) management in paddy fields leads to anaerobiosis by preventing atmospheric O2 to enter the soil. Methane is produced by decomposing soil organic matter with the process of methanogenesis. Methane is released to the atmosphere mostly via rice plants and small portions through ebullition and diffusion in the paddy water. Besides flooding conditions, soil temperature, soil pH, rice cultivar and straw incorporation are the main factors influencing CH4 productions in paddy ecosystems.",

      "Fortunately, there are clear interventions which mitigate CH4 emissions from paddy soil whilst supporting food security and improving livelihoods. Selection of suitable rice cultivars (e.g high yielding and salt tolerant varieties) and improved fertilizer management are two suggested options to reduce CH4 emission from paddy rice thereby contributing to lower global warming. However, alternate wetting and drying (AWD) is generally identified as the most promising solution which is environmentally and economically friendly. In place of CF, reducing flooding state by AWD tends to decrease CH4 producing bacteria and leads to up to 93% lower CH4 emissions. Adoption of AWD would additionally save water in the dry regions of Asia, where water scarcity is one of the major environmental problems. However, promoting and monitoring the adoption of agricultural management changes such as AWD from local to continental scale is challenging.",

      "Geotree facilitates nature-based solutions in rice ecosystems by supporting project implementation while significantly reducing the costs for monitoring, reporting and verification (MRV). Geotree’s cost-efficient, scalable and reliable MRV solutions build on suitable process-based models coupled with remotely sensed data. This allows policy makers, farmers, carbon credit buyers and other stakeholders to understand the alternative management strategies which reduce CH4 emissions and thereby contribute significantly to GHG mitigation within agriculture."
    ],
    image: image4
  },
  {
    id: 5,
    title: "Remote Sensing and Soil Organic Carbon",
    subtitle: "",
    description: [
      "The soil is by far the largest terrestrial carbon pool, largely exceeding the amounts stored in the atmosphere and the vegetation. At the same time, the carbon stored in soils permits long-term storage. Substantial amounts of additional atmospheric carbon can be absorbed by soils, and in particular by the most degraded soils, thereby enhancing its productivity, bio-diversity, and resistance against soil erosion.",

      "The fact that soils can sequester atmospheric CO2 in the form of soil organic carbon (SOC) offers a cost-efficient means to mitigate climate change. This has attracted the attention of environmental research and policy agendas. SOC is crucial to preserving soil quality and health and assuring food security. For example, maintaining or increasing SOC concentration improves other chemical and physical soil properties, such as nutrient storage, water holding capacity, aggregation and aggregate stability, and sorption of organic and/or inorganic pollutants. Increased carbon stocks also often go hand-in-hand with increased microbiological diversity and activity. Together, this underpins the growing need to spatially estimate and monitor SOC and also other soil properties. Ultimately, such a continuous monitoring at different spatial and temporal scales provides invaluable support and guidance for soil and land management as well as decision and policy-making.",

      "Unfortunately, high-resolution maps that indicate areas where SOC levels are critically low, and where improved management options could be most effective, are scarce. Current SOC maps at regional to global scale consist of rather coarse grid cells and are based on legacy data that is generally not up to date, as collecting and analysing new soil cores is expensive. Too little effort was spent in the past to extract the soil inherent optical properties, unaffected by remnants of crops and stubbles, changes in soil water content and roughness, which permit a robust SOC mapping across large areas. With the launch of the European Copernicus program and other complementary space activities, the availability and quality of remote sensing data has dramatically changed the paradigm. Together with the upcoming imaging spectrometers, remote sensing of (top) soils becomes feasible in a coherent manner from local to global scales – the use of high spatial and spectral resolution optical images from orbital platforms enables the development of soil monitoring and mapping techniques from the local to the regional, continental and global scales in an effective, fast, frequent, and economical way. Only satellite remote sensing offers the necessary high revisit cycles, to permit a comprehensive monitoring of large areas at fine spatial resolution.",

      "SOC shows a close relationship with the reflected electromagnetic radiation in visible (VIS), near‐infrared (NIR), and short wave-infrared (SWIR) regions. Therefore, several recent studies and scientific papers demonstrated the capability of satellite images, including the Copernicus Sentinel‐2 multi‐spectral instrument (MSI) and NASA’s Landsat‐8 operational land imager (OLI), for modelling SOC content and prediction and mapping of it, obtaining encouraging results. The spectral models can be further improved by including auxiliary predictor variables such as related to vegetation, topography, climate, and geology, which are often also highly correlated with SOC. The acquired data and retrieved information can be combined using physically-based, statistical, geostatistical, machine learning, and hybrid techniques to predict and monitor SOC at a fine spatial resolution with a consistent specification and uncertainty, for sustainable soil management and decision-making.",

      "Based on the Geotree team’s deep understanding of the physical interaction of electromagnetic radiation with matter, we have developed a robust SOC modelling approach based on time-series of high-resolution Sentinel-2 data. The developed approach is robust against changes in soil wetness and roughness whilst also detecting and removing spectral effects of residual vegetation cover and stubble. In this way, Geotree obtains from the Sentinel-2 time-series soil inherent optical properties (SIOP) that are closely related to SOC as well as other soil properties. This revolutionary approach will allow for scalable and regular global soil carbon monitoring."
    ],
    image: image5
  },
  {
    id: 6,
    title: "The role of nature-based solutions in fighting climate change",
    subtitle:
      "Whilst engineered solutions will likely have some role in combatting climate change, nature-based solutions are primed and ready to have a major part to play.",
    description: [
      "Over the past 10 years, there has been a growing interest in these solutions, which can include conservation, restoration, and improved land management.",

      "Nature-based solutions store carbon in areas such as forests, wetlands, peatlands, and grasslands, and provide additional benefits from preserving and restoring the natural world, such as conserving wildlife habitats, protecting biodiversity, improving water quality, and reducing flood risk.",

      "All of these benefits can enhance the wellbeing and financial security of communities around the world.",

      "The IPCC Climate Change and Land Report states that all scenarios to limit global temperature increases to 1.5 degrees will “rely heavily” on changing the use of land, as well as decarbonising the economy.",

      "It also estimates that at least 23% of greenhouse gases come from land use and land-use change. Therefore, any credible plans to tackle climate change must include an end to deforestation, climate-positive agriculture and the protection of natural ecosystems such as coastal wetlands.",

      "Some experts believe that using nature-based solutions, as part of global climate change efforts, could contribute at least 20% of the mitigation needed between now and 2050 to keep global warming below 2 degrees.",

      "A report published earlier this year by the World Economic Forum estimates that nature-based solutions could help save around 7 gigatons of carbon dioxide, which would be a third of necessary amount to limit global warming by 1.5 or 2 degrees by 2030.",

      "It also highlights the cost-effectiveness of such schemes. In most cases, it argues costs are between $10 and $40 per ton of CO2.",

      "As most people know, plant life and vegetation naturally absorbs carbon.",

      "Recent research published in Nature Climate Change found that the world’s forests sequestered about twice as much carbon dioxide as they emitted between 2001 and 2019.",

      "In other words, forests provide a “carbon sink” that absorbs a net 7.6 billion metric tonnes of CO2 per year, 1.5 times more carbon than the United States emits annually.",

      "But there is more to nature-based solutions than just protecting the world’s forests. For example, the world’s peatlands store twice as much carbon as the global forests. Seagrass can absorb carbon 35 times faster than rainforests, and saltmarsh is one of the most biologically productive habitats on the planet.",

      "But the adoption of nature-based solutions has not been without its controversy. The World Economic Forum report notes such schemes are being “held back” from fulfilling their potential by “various conceptual and technical hurdles”, starting with a lack of consensus on how to treat corporate carbon claims.",

      "Looking forward, the monitoring, reporting and verification of such projects will be key to ensure these schemes thrive, as well as deliver much-needed carbon reductions. But the evidence is clear, nature-based solutions offer value for money and a host of economic and social benefits that cannot be ignored."
    ],
    image: image6
  }
];
