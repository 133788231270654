import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useInView } from "framer-motion";
import "animate.css";

import "./insightsDetails.scss";
import blogWritter from "../../assets/images/teams/team1.png";
// import BlogImage from "../../assets/images/teams/blogImage.png";

import linkdin from "../../assets/images/about-us/linkdin.svg";
import facebook from "../../assets/images/about-us/facebook.svg";
import twitter from "../../assets/images/about-us/twitter.svg";
import InsightCard from "components/InsightCard/index";
import { insightsData } from "constant/insightData";
import readnow from "assets/icons/readnow.svg";

import image5 from "assets/images/Container-3.png";
import image4 from "assets/images/Container-2.png";
import image7 from "assets/images/Container-video1.png";
import image6 from "assets/images/Container-4.png";
import image8 from "assets/images/Container-video2.png";
// import image9 from "assets/images/Container-video3.png";
import image3 from "assets/images/Container-1.png";

function InsightsDetails() {
  const navigate = useNavigate();
  const insightDetails = useRef();
  const insightDetailsInView = useInView(insightDetails, {
    amount: 0.1,
    once: true
  });

  const articles = [
    {
      id: 1,
      title: "",
      subtitle: "",
      description:
        "Fostering voluntary, nature-based GHG offsets through cost-efficient MRV tools | Part 1",
      image: image7,
      buttonText: "Read Now",
      buttonIcon: readnow
    },
    {
      id: 2,
      title: "",
      subtitle: "",
      description:
        "Fostering voluntary, nature-based GHG offsets through cost-efficient MRV tools | Part 2",
      image: image8,
      buttonText: "Read Now",
      buttonIcon: readnow
    },
    {
      id: 3,
      title: "",
      subtitle: "",
      description: "Landscape Carbon Modelling",
      image: image3,
      buttonText: "Read Now",
      buttonIcon: readnow
    },
    {
      id: 4,
      title: "",
      subtitle: "",
      description: "Rice Methane Emissions",
      image: image4,
      buttonText: "Read Now",
      buttonIcon: readnow
    },
    {
      id: 5,
      title: "",
      subtitle: "",
      description: "Remote Sensing and Soil Organic Carbon",
      image: image5,
      buttonText: "Read Now",
      buttonIcon: readnow
    },
    {
      id: 6,
      title: "",
      subtitle: "",
      description:
        "The role of nature-based solutions in fighting climate change",
      image: image6,
      buttonText: "Read Now",
      buttonIcon: readnow
    }
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  const singleInsightsData = insightsData.find(
    (item) => item.id === parseInt(id)
  );

  const filterArticles = articles.filter((article) => article.id != id);

  const handleNavigation = (id) => {
    navigate(`/insights/${id}`);
    window.scrollTo(0, 0);
  };
  return (
    <section className="in-section" ref={insightDetails}>
      <div className="in-main-container in-container">
        {/* {singleInsightsData.map((data, id) => { */}
        <div key={id} className="in-main-container">
          <h3 className="in-details-title animate__animated animate__fadeInUp">
            {singleInsightsData.title}
          </h3>
          <div className="in-blog-writer-container animate__animated animate__fadeInUp">
            <img
              className="in-blog-writer-icon animate__animated animate__fadeInUp"
              src={blogWritter}
              alt=""
            />
            <div>
              <h4 className="in-blog-writer-name animate__animated animate__fadeInUp">
                Dr Clement Atzberger
              </h4>
              <span className="in-blog-writer-role animate__animated animate__fadeInUp">
                Head of Research
              </span>
            </div>
          </div>
          <div className="in-blog-image animate__animated animate__fadeInUp">
            <img src={singleInsightsData.image} alt="" />
          </div>

          {singleInsightsData.subtitle && (
            <div
              className={`in-details-subtitle-container ${
                insightDetailsInView
                  ? "animate__animated animate__fadeInUp"
                  : "animate__animated animate__fadeOutUp"
              }`}
            >
              <h4 className="in-details-subtitle">
                {singleInsightsData.subtitle}
              </h4>
            </div>
          )}

          {singleInsightsData.description.map((para, id) => {
            if (Array.isArray(para)) {
              return (
                <ul key={id}>
                  {para.map((item) => (
                    <li key={para}>
                      {item}
                      <br />
                    </li>
                  ))}
                  <br />
                </ul>
              );
            } else {
              return (
                <p
                  key={id}
                  className={
                    insightDetailsInView
                      ? "animate__animated animate__fadeInUp"
                      : "animate__animated animate__fadeOutUp"
                  }
                >
                  {para}
                  <br />
                  <br />
                </p>
              );
            }
          })}

          <div className="in-blogshare-container">
            <p className="in-details-description">Share this</p>
            <div className="in-share-icon-container">
              <div className="in-share-icon">
                <img src={twitter} alt="" />
              </div>
              <div className="in-share-icon">
                <img src={facebook} alt="" />
              </div>
              <div className="in-share-icon">
                <img className="" src={linkdin} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="in-suggetion-container">
        <h3 className="in-details-title">Related Insights</h3>
        <div className="in-suggetion-card-container">
          {filterArticles.slice(0, 3).map((article, index) => (
            <InsightCard key={index} data={article} action={handleNavigation} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default InsightsDetails;
